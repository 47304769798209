import { createTranslations } from '../../utils/i18n';

interface Fields {
  firstSentence: string;
  secondSentence: string;
  subheading: string;
  primaryButtonText: string;
  secondaryButtonText: string;
}

export const channelsSectionTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        firstSentence: 'Identifies the best channels to optimize',
        secondSentence: 'your e-commerce',
        subheading:
          'Analyze all data from acquisition channels and optimize your business budget',
        primaryButtonText: 'Know more',
        secondaryButtonText: "I'm an agency",
      },
      pt: {
        firstSentence: 'Identifique os melhores canais para otimizar ',
        secondSentence: 'o seu e-commerce',
        subheading:
          'Analise todos os dados de canais de aquisição e otimize o orçamento do seu negócio',
        primaryButtonText: 'Saiba mais',
        secondaryButtonText: 'Sou uma agência',
      },
    },
  });
