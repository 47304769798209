import { createTranslations } from '../../utils/i18n';

interface Fields {
  firstSentence: string;
  secondSentence: string;
  subheading: string;
  primaryButtonText: string;
}

export const productAnalyticsSectionTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        firstSentence: 'ROAS down?',
        secondSentence:
          'Keep track of the stock of the main products in seconds',
        subheading: 'And forecast the durantion of stock of each SKU',
        primaryButtonText: 'Learn more',
      },
      pt: {
        firstSentence: 'ROAS caindo?',
        secondSentence:
          'Acompanhe o estoque dos principais produtos em segundos',
        subheading: 'E tenha previsões de duração do estoque de cada SKU',
        primaryButtonText: 'Saiba mais',
      },
    },
  });
