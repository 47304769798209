import {
  Box,
  chakra,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { parseValueToType } from '../../../utils/parseValueToType';
import { translations } from './translations';

interface StatsCardProps {
  title: string;
  stat: string;
  isMonetary?: boolean;
}

function StatsCard(props: StatsCardProps) {
  const { title, stat, isMonetary } = props;

  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid '}
      borderColor={useColorModeValue('#7903EF', '#1C45D6')}
      rounded={'lg'}
    >
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }}>
          <Text
            bgGradient="linear(to-r, brand.blue, brand.purple)"
            display={'flex'}
            bgClip="text"
            as={'span'}
            fontFamily="inter"
            fontWeight={'600'}
            fontSize={32}
          >
            +{isMonetary && <Flex>R$&nbsp;</Flex>}
            {stat}
          </Text>

          <StatLabel fontSize={16} fontWeight={'700'} isTruncated>
            {title}
          </StatLabel>
        </Box>
        {/* <Box
          my={'auto'}
          color={useColorModeValue('gray.800', 'gray.200')}
          alignContent={'center'}>
          {icon}
        </Box> */}
      </Flex>
    </Stat>
  );
}

export function Statistics() {
  const router = useRouter();
  const t = translations(router.locale);
  return (
    <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <chakra.h1
        fontFamily={'Inter'}
        textAlign={'center'}
        fontSize={'4xl'}
        py={10}
        fontWeight={'bold'}
        color={'gray.700'}
      >
        {t.title}
      </chakra.h1>
      <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 8 }}>
        <StatsCard
          title={t.revenue}
          stat={parseValueToType.integer(2500000000)}
          isMonetary
        />
        <StatsCard
          title={t.investment}
          stat={parseValueToType.integer(600000000)}
          isMonetary
        />
        <StatsCard title={t.orders} stat={parseValueToType.integer(8000000)} />
        <StatsCard title={t.ecoms} stat={parseValueToType.integer(700)} />
      </SimpleGrid>
    </Box>
  );
}
