import { createTranslations } from '../../../utils/i18n';

export const translations = (locale?: string) =>
  createTranslations({
    locale,
    data: {
      en: {
        emailPlaceholder: 'Work e-mail',
        emailErrorMessage: 'Need to be a valid e-mail',
        noCard: 'No card needed! ',
      },
      pt: {
        emailPlaceholder: 'E-mail profissional',
        emailErrorMessage: 'Precisa ser um e-mail válido',
        noCard: 'Não precisa de cartão!',
      },
    },
  });
