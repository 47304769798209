import { createTranslations } from '../../utils/i18n';

interface Fields {
  firstSentence: string;
  secondSentence: string;
  subheading: string;
  primaryButtonText: string;
  secondaryButtonText: string;
}

export const defaultHeroSectionTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        firstSentence: 'All of your customers,',
        secondSentence: 'one tab',
        subheading:
          'The only solution focused on e-com agencies. We gather data from all of your clients so you can focus on what really mathers, incresing revenue',
        primaryButtonText: 'Learn more',
        secondaryButtonText: "I'm an agency",
      },
      pt: {
        firstSentence: 'Todos os seus clientes,',
        secondSentence: 'uma tela',
        subheading:
          'Uma solução focada em agências de e-commerce. Centralizamos os dados de todos os seus clientes para você focar no que importa, entregar resultado',
        primaryButtonText: 'Saiba mais',
        secondaryButtonText: 'Sou uma agência',
      },
    },
  });
