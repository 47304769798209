import { chakra } from '@chakra-ui/react';
import { createTranslations } from '../../../utils/i18n';

interface Fields {
  upperHeading: string;
  title: string;
  subheading: React.ReactElement;
  firstPersonOcupation: string;
  secondPersonOcupation: string;
  firstPersonTestemonial: string;
  secondPersonTestemonial: string;
}

export const translations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        upperHeading: 'People love us',
        title: 'You are in great company',
        subheading: (
          <chakra.h2
            margin={'auto'}
            width={'70%'}
            fontWeight={'medium'}
            fontSize={24}
            color={'gray.500'}
          >
            See why{' '}
            <chakra.strong color={'gray.700'} fontSize={24}>
              so many e-com and agency owners
            </chakra.strong>{' '}
            are using our software
          </chakra.h2>
        ),
        firstPersonOcupation: 'CEO',
        firstPersonTestemonial:
          'Before Adstart, our traffic managers were only able to work with 12 e-coms at a time, now they work with 18',
        secondPersonOcupation: 'E-commerce manager',
        secondPersonTestemonial:
          'Adstart was truly a game changer for us, our productivity and conversion increased a lot.',
      },
      pt: {
        upperHeading: 'As pessoas amam o Adstart',
        title: 'Você está em boa companhia',
        subheading: (
          <chakra.h2
            margin={'auto'}
            width={'70%'}
            fontWeight={'medium'}
            fontSize={24}
            color={'gray.500'}
          >
            Veja porquê{' '}
            <chakra.strong color={'gray.700'} fontSize={24}>
              tantos profissionais de marketing
            </chakra.strong>{' '}
            estão usando nossa ferramenta
          </chakra.h2>
        ),
        firstPersonOcupation: 'CEO',
        firstPersonTestemonial:
          'Antes da Adstart, nossos gestores de tráfego conseguiam atender apenas 12 e-commerces, hoje eles conseguem atender 18.',
        secondPersonOcupation: 'E-commerce manager',
        secondPersonTestemonial:
          'Com a Adstart nós conseguimos ter uma visão clara do resultado de performance da Yeesco em segundos.',
      },
    },
  });
