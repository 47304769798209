import { createTranslations } from '../../utils/i18n';

interface Fields {
  title: string;
  subTitle: string;
  buttonText: string;
}

export const orbitSectionTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        title: 'A new way of thinking about your data',
        subTitle:
          'Adstart integrates your ads & orders and provides a unique performance data platform for e-com agency',
        buttonText: 'Explore Adstart',
      },
      pt: {
        title: 'Uma nova maneira de pensar em dados',
        subTitle:
          'A Adstart integra seus dados de anúncios e e-commerces, formando uma plataforma única de performance para sua agência de e-commerce',
        buttonText: 'Saiba mais',
      },
    },
  });
