import {
  Container,
  Stack,
  Flex,
  Heading,
  Text,
  Button,
  Icon,
  Box,
} from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FiChevronRight } from 'react-icons/fi';

interface LandingPageDefaultSectionProps {
  textOrientation: 'left' | 'right';
  title: string;
  titleWithGradient: string;
  subtitle: string;
  cta: string;
  imageUrl: string;
  widthImageUrl: string;
  heightImageUrl: string;
}

export function LandingPageDefaultSection({
  cta,
  imageUrl,
  subtitle,
  textOrientation,
  title,
  titleWithGradient,
  heightImageUrl,
  widthImageUrl,
}: LandingPageDefaultSectionProps) {
  const router = useRouter();
  return (
    <Container maxW={'7xl'} h={{ base: 'fit-content' }}>
      <Stack
        align={'center'}
        height={'100%'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 8, md: 16 }}
        direction={{
          base: 'column-reverse',
          md: textOrientation === 'right' ? 'row' : 'row-reverse',
        }}
      >
        <Button
          display={{ base: 'flex', md: 'none' }}
          rounded={'full'}
          size={'lg'}
          fontWeight={'600'}
          fontSize={20}
          px={6}
          as="a"
          onClick={() => {
            router.push('/trial');
          }}
          target={'_blank'}
          color="#fff"
          bgGradient="linear(to-r, brand.blue, brand.purple)"
          _hover={{
            opacity: '0.8',
            transition: '0.2s',
          }}
        >
          {cta}
        </Button>
        <Flex
          flex={1}
          paddingTop={{
            base: 6,
            md: 0,
          }}
          transition={'all ease 200ms'}
          _hover={{
            transform: 'scale(1.03)',
          }}
          align="center"
          justify={'center'}
          position={'relative'}
          w={'full'}
        >
          <Image
            alt={'Hero Image'}
            objectFit={'cover'}
            width={widthImageUrl}
            height={heightImageUrl}
            src={imageUrl}
          />
        </Flex>

        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.3}
            fontWeight={600}
            fontFamily="Inter"
            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
          >
            <Text as={'span'} color="gray.700" position={'relative'}>
              {title}
            </Text>
            <br />
            <Text
              bgGradient="linear(to-r, brand.blue, brand.purple)"
              bgClip="text"
              as={'span'}
            >
              {titleWithGradient}
            </Text>
          </Heading>

          <Text color={'gray.500'} fontSize={{ base: 20, sm: 24 }}>
            {subtitle}
          </Text>

          <Link passHref href="/trial">
            <Box
              display={{ base: 'none', md: 'flex' }}
              fontWeight={'700'}
              width={'fit-content'}
              fontSize={18}
              bgClip={'text'}
              role={'group'}
              bgGradient="linear(to-r, brand.blue, brand.purple)"
              flexDirection={'row'}
              alignItems={'center'}
              cursor={'pointer'}
              _hover={{
                opacity: '0.8',
                transition: '0.2s',
              }}
            >
              {cta}
              <Flex
                ml={2}
                transition={'all .3s ease'}
                transform={'translateX(-5px)'}
                _groupHover={{
                  transform: 'translateX(0)',
                }}
                justify={'flex-end'}
                align={'center'}
                flex={1}
              >
                <Icon color={'brand.purple'} w={5} h={5} as={FiChevronRight} />
              </Flex>
            </Box>
          </Link>
        </Stack>
      </Stack>
    </Container>
  );
}
