import { Box, Flex, Text } from '@chakra-ui/react';

import { Hero } from '../components/Landing/Hero';
import KnowMore from '../components/Landing/KnowMore';
import Testemonials from '../components/Landing/Testemonials';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { Orbit } from '../components/Landing/Orbit';

import { LandingPageDefaultSection } from '../components/Landing/LandingPageDefaultSection';
import { agenciesSectionTranslations } from '../utils/landingPageTranslations/agenciesSectionTranslations';
import { defaultHeroSectionTranslations } from '../utils/landingPageTranslations/defaultHeroSectionTranslations';
import { orbitSectionTranslations } from '../utils/landingPageTranslations/orbitSectionTranslations';
import { Base } from '../components/Landing/Base';
import { channelsSectionTranslations } from '../utils/landingPageTranslations/channelsSectionTranslations';
import { seoSectionTranslations } from '../utils/landingPageTranslations/seoSectionTranlations';
import { trackUserEvent } from '../utils/trackUserEvent';
import { GetServerSideProps } from 'next';
import { productAnalyticsSectionTranslations } from '../utils/landingPageTranslations/productAnalyticsTranslations';
import { goalsSectionTranslations } from '../utils/landingPageTranslations/goalsSectionTranslations';
import { Statistics } from '../components/Landing/Statistics';
import Link from 'next/link';

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  await trackUserEvent({
    ctx,
  });

  if (
    ctx.resolvedUrl === '/' &&
    ctx.req.headers.host === 'ecommercedashboard.com.br'
  ) {
    return {
      props: {},
      redirect: {
        destination: '/login',
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
};

export default function LandingPage() {
  const { locale } = useRouter();

  const agenciesTranslations = agenciesSectionTranslations(locale);
  const defaultHeroTranslations = defaultHeroSectionTranslations(locale);
  const orbitTranslations = orbitSectionTranslations(locale);
  const channelsTranslations = channelsSectionTranslations(locale);
  const seoTranslations = seoSectionTranslations(locale);
  const productAnalyticsTranslations =
    productAnalyticsSectionTranslations(locale);
  const goalsTranslations = goalsSectionTranslations(locale);

  return (
    <>
      <Head>
        <title>
          {' '}
          {locale === 'pt'
            ? 'Adstart | Todas as plataformas, uma aba'
            : 'Adstart | Every ad platform, one tab'}
        </title>
        {/* <!-- Primary Meta Tags --> */}
        <meta
          name="title"
          content="Adstart | Todas as plataformas, uma aba"
          lang="pt"
        />
        <meta
          name="description"
          content="A Adstart é uma plataforma de gerenciamento de ads. Ajudamos equipes de marketing simplificando a visualização de dados"
          lang="pt"
        />
        <meta
          name="keywords"
          content="ad, ads, anúncio, anúncios, gerenciamento de ads, gerenciamento de anúncios, plataforma de ads, plataforma de anúncios, cpc, cpp, ctr, cpa, clicks, como aumentar número de clicks, como fazer meu cliente clicar no anúncio, cliente, como diminuir cpc, gastar menos em ads, gastar menos em anúncios, otimizar anúncios, anúncios converterem mais, conversão, aumentar conversão, melhorar anúncios, melhorar ads, ads pagos, facebook, instagram, twitter, google, google ads, facebook ads, anúncios no facebook, otimizar tempo, equipe de marketing, gerenciamento equipe marketing, ad sense, seo, roas, roi, como aumentar meu roi, aumenta a conversão"
          lang="pt"
        />
        <meta
          name="title"
          content="Adstart | Every ad platform, one tab"
          lang="en"
        />
        <meta
          name="description"
          content="Adstart is an ad management platform. We help marketing teams by simplifying data visualization"
          lang="en"
        />
        <meta
          name="keywords"
          content="ad, ads, ad management, ad platform, ads platform, cpc, cpp, ctr, cpa, clicks"
          lang="en"
        />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" lang="pt" />
        <meta property="og:url" content="https://Adstart.com.br/" lang="pt" />
        <meta
          property="og:title"
          content="Adstart | Todas as plataformas, uma aba"
          lang="pt"
        />
        <meta
          property="og:description"
          content="A Adstart é uma plataforma de gerenciamento de ads. Ajudamos equipes de marketing simplificando a visualização de dados"
          lang="pt"
        />
        <meta property="og:image" content="/logo.svg" lang="pt" />

        <meta property="og:type" content="website" lang="en" />
        <meta property="og:url" content="https://Adstart.com.br/en" lang="en" />
        <meta
          property="og:title"
          content="Adstart | Every ad platform, one tab"
          lang="en"
        />
        <meta
          property="og:description"
          content="Adstart is an ad management platform. We help marketing teams by simplifying data visualization"
          lang="en"
        />
        <meta property="og:image" content="/logo.svg" lang="en" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" lang="pt" />
        <meta
          property="twitter:url"
          content="https://Adstart.com.br/pt"
          lang="pt"
        />
        <meta
          property="twitter:title"
          content="Adstart | Todas as plataformas, uma aba"
          lang="pt"
        />

        <meta
          property="twitter:description"
          content="A Adstart é uma plataforma de gerenciamento de ads. Ajudamos equipes de marketing simplificando a visualização de dados"
          lang="pt"
        />

        <meta property="twitter:image" content="/logo.svg" lang="pt" />

        <meta property="twitter:card" content="summary_large_image" lang="en" />
        <meta
          property="twitter:url"
          content="https://Adstart.com.br/en"
          lang="en"
        />
        <meta
          property="twitter:title"
          content="Adstart | Every ad platform, one tab"
          lang="en"
        />

        <meta
          property="twitter:description"
          content="Adstart is an ad management platform. We help marketing teams by simplifying data visualization"
          lang="en"
        />

        {/* internationalization */}
        <link rel="alternate" href="https://Adstart.com.br/en" hrefLang="en" />
        <link
          rel="alternate"
          href="https://Adstart.com.br/pt"
          hrefLang="pt-br"
        />
        <link
          rel="alternate"
          href="https://Adstart.com.br/en"
          hrefLang="x-default"
        />
      </Head>
      <Flex
        as="header"
        align="center"
        justify="center"
        position="sticky"
        top="0"
        zIndex="sticky"
        bg="black"
        color="white"
        height="40px"
        boxShadow="sm"
        py={30}
      >
        <Text fontSize="sm" fontWeight={700} textAlign="center" flex="1">
          ✨ Conheça nossa nova solução de IA,{' '}
          <Link href="/ai" passHref>
            <Text style={{ textDecoration: 'underline' }}>clique aqui</Text>
          </Link>
        </Text>
      </Flex>
      <Base>
        <Hero
          companyType="AGENCY"
          cta={defaultHeroTranslations.primaryButtonText}
          imageUrl="/prints/businesses.png"
          subtitle={defaultHeroTranslations.subheading}
          title={defaultHeroTranslations.firstSentence}
          titleGradient={defaultHeroTranslations.secondSentence}
          imageSize={{ widthInPx: 620, heightInPx: 410 }}
        />

        <Orbit
          middleLogo="/adstart-circle-logo.svg"
          cta={orbitTranslations.buttonText}
          subtitle={orbitTranslations.subTitle}
          title={orbitTranslations.title}
        />

        <LandingPageDefaultSection
          heightImageUrl="410px"
          widthImageUrl="620px"
          cta={agenciesTranslations.primaryButtonText}
          imageUrl="/prints/dash.png"
          subtitle={agenciesTranslations.subheading}
          textOrientation="right"
          title={agenciesTranslations.firstSentence}
          titleWithGradient={agenciesTranslations.secondSentence}
        />
        <LandingPageDefaultSection
          heightImageUrl="410px"
          widthImageUrl="620px"
          cta={productAnalyticsTranslations.primaryButtonText}
          imageUrl="/prints/product-analytics.png"
          subtitle={productAnalyticsTranslations.subheading}
          textOrientation="left"
          title={productAnalyticsTranslations.firstSentence}
          titleWithGradient={productAnalyticsTranslations.secondSentence}
        />
        <LandingPageDefaultSection
          heightImageUrl="410px"
          widthImageUrl="620px"
          cta={goalsTranslations.primaryButtonText}
          imageUrl="/prints/goals.png"
          subtitle={goalsTranslations.subheading}
          textOrientation="right"
          title={goalsTranslations.firstSentence}
          titleWithGradient={goalsTranslations.secondSentence}
        />
        <LandingPageDefaultSection
          heightImageUrl="400px"
          widthImageUrl="600px"
          cta={channelsTranslations.primaryButtonText}
          imageUrl="/prints/channels-page.png"
          subtitle={channelsTranslations.subheading}
          textOrientation="left"
          title={channelsTranslations.firstSentence}
          titleWithGradient={channelsTranslations.secondSentence}
        />

        <LandingPageDefaultSection
          heightImageUrl="400px"
          widthImageUrl="600px"
          cta={seoTranslations.primaryButtonText}
          imageUrl="/prints/seo.png"
          subtitle={seoTranslations.subheading}
          textOrientation="right"
          title={seoTranslations.firstSentence}
          titleWithGradient={seoTranslations.secondSentence}
        />

        <Testemonials />
        <Statistics />

        <Box height={10} />

        <KnowMore />
      </Base>
    </>
  );
}
