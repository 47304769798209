import { createTranslations } from '../../utils/i18n';

interface Fields {
  firstSentence: string;
  secondSentence: string;
  subheading: string;
  primaryButtonText: string;
}

export const seoSectionTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        firstSentence: 'Discover the best SEO strategy for you with ',
        secondSentence: 'valuable insights',
        subheading:
          'Track SEO performance by knowing the most popular pages and keywords for your business',
        primaryButtonText: 'Learn more',
      },
      pt: {
        firstSentence: 'Descubra a melhor estratégia de SEO com',
        secondSentence: 'insights valiosos',
        subheading:
          'Acompanhe o desempenho de SEO sabendo as páginas e palavras-chave mais populares do seu negócio',
        primaryButtonText: 'Saiba mais',
      },
    },
  });
