import {
  Box,
  Button,
  Flex,
  Image,
  keyframes,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';

interface OrbitProps {
  middleLogo: string;
  title: string;
  subtitle: string;
  cta: string;
}

export function Orbit({ cta, subtitle, title, middleLogo }: OrbitProps) {
  const router = useRouter();

  const onCtaClick = () => {
    router.push('/trial');
  };

  const spinRight = keyframes`
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  `;

  const spinLeft = keyframes`
  100% {
      -webkit-transform: rotate(-360deg);
      -moz-transform: rotate(-360deg);
      -ms-transform: rotate(-360deg);
      -o-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  `;

  const ORBIT_DIAMETER = 564;

  return (
    <Stack
      minH={{ base: 0, md: '100vh' }}
      maxW="100vw"
      py={{ base: 6, md: 0 }}
      bgGradient="linear(to-r, #050542, #050542, #050542)"
      flexDir={{ md: 'row', base: 'column' }}
      px={{ base: 8, md: 20 }}
      spacing={cta === '' && subtitle === '' && title === '' ? 0 : 8}
      direction={{ md: 'row', base: 'column' }}
    >
      <Flex
        display={
          cta === '' && subtitle === '' && title === '' ? 'none' : 'flex'
        }
        align={{ base: 'center', md: 'flex-start' }}
        justify="center"
        mb={10}
        flexDir="column"
        mt={4}
        textAlign={{ base: 'center', md: 'start' }}
      >
        <Text
          color="#fff"
          fontFamily="Inter"
          fontWeight={600}
          fontSize={{ md: 40, base: 32 }}
          mb={6}
        >
          {title}
        </Text>
        <Text
          color="#fff"
          fontFamily="Nunito"
          fontWeight={600}
          fontSize={{ md: 24, base: 22 }}
          mb={10}
        >
          {subtitle}
        </Text>
        <Button
          bg="#fff"
          borderRadius={50}
          fontSize={20}
          mb={12}
          onClick={onCtaClick}
        >
          <Text
            bgGradient="linear(to-l, brand.blue, brand.purple)"
            bgClip="text"
            fontFamily="Nunito"
            fontWeight={700}
          >
            {cta}
          </Text>
        </Button>
      </Flex>

      <Flex
        backgroundImage={'/stars.svg'}
        backgroundRepeat="repeat"
        justifyContent="center"
        alignItems="center"
        width={{ base: 'initial', md: 'max-content' }}
        minW={{ base: 'fit-content', md: '700px' }}
      >
        <Box
          id="circle-orbit-container"
          borderRadius={'100%'}
          height={{
            md: `${ORBIT_DIAMETER}px`,
            base: `${ORBIT_DIAMETER / 2}px`,
          }}
          width={{ md: `${ORBIT_DIAMETER}px`, base: `${ORBIT_DIAMETER / 2}px` }}
          transform={'skew(-10deg)'}
        >
          <Box
            id="inner-orbit"
            position="absolute"
            top={{
              md: `${ORBIT_DIAMETER / 4}px`,
              base: `${ORBIT_DIAMETER / 8}px`,
            }}
            left={{
              md: `${ORBIT_DIAMETER / 4}`,
              base: `${ORBIT_DIAMETER / 8}px`,
            }}
            height={{
              md: `${ORBIT_DIAMETER / 2}px`,
              base: `${ORBIT_DIAMETER / 4}px`,
            }}
            width={{
              md: `${ORBIT_DIAMETER / 2}px`,
              base: `${ORBIT_DIAMETER / 4}px`,
            }}
            border="2px #fff dashed"
            borderRadius="100%"
            animation={`${spinRight} 15s linear infinite`}
          >
            <Box
              position="absolute"
              top={{ md: '-30px', base: '-15px' }}
              left={{ md: '100px', base: '50px' }}
              height={{ md: '64px', base: '32px' }}
              width={{ md: '64px', base: '32px' }}
              borderRadius="100%"
              animation={`${spinLeft} 15s linear infinite`}
              bg="gray.100"
            >
              <Image
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                alt="vtex-circle-logo"
                src="/tiktok-circle-logo.svg"
              />
            </Box>

            <Box
              position="absolute"
              top={{ md: '160px', base: '80px' }}
              left={{ md: '230px', base: '115px' }}
              height={{ md: '64px', base: '32px' }}
              width={{ md: '64px', base: '32px' }}
              borderRadius="100%"
              animation={`${spinLeft} 15s linear infinite`}
              bg="gray.100"
            >
              <Image
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                alt="tray-logo"
                src="/google-circle-logo.svg"
              />
            </Box>

            <Box
              position="absolute"
              top={{ md: '160px', base: '80px' }}
              left={{ md: '-20px', base: '-10px' }}
              height={{ md: '64px', base: '32px' }}
              width={{ md: '64px', base: '32px' }}
              borderRadius="100%"
              animation={`${spinLeft} 15s linear infinite`}
              bg="gray.100"
            >
              <Image
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                alt="meta-logo"
                src="/meta-circle-logo.svg"
              />
            </Box>
          </Box>

          <Box
            position="absolute"
            flexDirection={'column'}
            top={{ md: '236px', base: '118px' }}
            left={{ md: '236px', base: '118px' }}
            height={{ md: '92px', base: '46px' }}
            width={{ md: '92px', base: '46px' }}
            borderRadius={{ md: 12, base: 6 }}
            boxShadow={'2xl'}
            display={'flex'}
            alignItems="center"
            justifyContent={'center'}
            bg="#fff"
          >
            <Image
              mt={middleLogo !== '/chatgpt-circle-logo.svg' ? 0 : 1}
              src={middleLogo}
              alt={middleLogo}
              height={{ md: '64px', base: '32px' }}
              width={{ md: '64px', base: '32px' }}
            />
            <Text
              display={
                middleLogo !== '/chatgpt-circle-logo.svg'
                  ? 'none'
                  : { md: 'unset', base: 'unset' }
              }
              fontSize={{ md: 18, base: 10 }}
              fontWeight={'bold'}
            >
              ChatGPT
            </Text>
          </Box>

          <Box
            id="middle-orbit"
            position="absolute"
            top={{ md: `${ORBIT_DIAMETER / 8}`, base: '35.25px' }}
            left={{ md: `${ORBIT_DIAMETER / 8}`, base: '35.25px' }}
            width={{ md: '423px', base: '211.5px' }}
            height={{ md: '423px', base: '211.5px' }}
            border="2px #fff dashed"
            borderRadius="100%"
            animation={`${spinRight} 20s linear infinite`}
          >
            <Box
              id="middle-orbit-cirlces"
              position="absolute"
              top={{ md: '25px', base: '12.5px' }}
              left={{ md: '34px', base: '17px' }}
              height={{ md: '64px', base: '32px' }}
              width={{ md: '64px', base: '32px' }}
              borderRadius="100%"
              animation={`${spinLeft} 20s linear infinite`}
              bg="gray.200"
            >
              <Image
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                alt="Instagram logo"
                src="/instagram-circle-logo.svg"
              />
            </Box>

            <Box
              id="middle-orbit-cirlces"
              position="absolute"
              top={{ md: '20px', base: '10px' }}
              left={{ md: '324px', base: '162px' }}
              height={{ md: '64px', base: '32px' }}
              width={{ md: '64px', base: '32px' }}
              borderRadius="100%"
              animation={`${spinLeft} 20s linear infinite`}
              bg="gray.200"
            >
              <Image
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                alt="Google analytics logo"
                src="/google-analytics-circle-logo.svg"
              />
            </Box>

            <Box
              id="middle-orbit-cirlces"
              position="absolute"
              top={{ md: '380px', base: '190px' }}
              left={{ md: '184px', base: '92px' }}
              height={{ md: '64px', base: '32px' }}
              width={{ md: '64px', base: '32px' }}
              borderRadius="100%"
              animation={`${spinLeft} 20s linear infinite`}
              bg="gray.200"
            >
              <Image
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                alt="Google search console logo"
                src="/google-search-console-circle-logo.svg"
              />
            </Box>
          </Box>

          <Box
            id="outer-orbit"
            position="absolute"
            top="0"
            left="0"
            height={{
              md: `${ORBIT_DIAMETER}px`,
              base: `${ORBIT_DIAMETER / 2}px`,
            }}
            width={{
              md: `${ORBIT_DIAMETER}px`,
              base: `${ORBIT_DIAMETER / 2}px`,
            }}
            border="2px #fff dashed"
            borderRadius="100%"
            animation={`${spinRight} 25s linear infinite`}
          >
            <Box
              id="outer-orbit-cirlces"
              position="absolute"
              top={{ md: '-26px', base: '-13px' }}
              left={{ md: '264px', base: '132px' }}
              height={{ md: '64px', base: '32px' }}
              width={{ md: '64px', base: '32px' }}
              borderRadius="100%"
              bg="gray.200"
              animation={`${spinLeft} 25s linear infinite`}
            >
              <Image
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                alt="Nuvemshop logo"
                src="/nuvemshop-circle-logo.svg"
              />
            </Box>

            <Box
              id="outer-orbit-cirlces"
              position="absolute"
              top={{ md: '150px', base: '75px' }}
              left={{ md: '510px', base: '255px' }}
              height={{ md: '64px', base: '32px' }}
              width={{ md: '64px', base: '32px' }}
              borderRadius="100%"
              bg="gray.200"
              animation={`${spinLeft} 25s linear infinite`}
            >
              <Image
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                alt="magazord logo"
                src="/magazord-circle-logo.svg"
              />
            </Box>

            <Box
              id="outer-orbit-cirlces"
              position="absolute"
              top={{ md: '450px', base: '225px' }}
              left={{ md: '450px', base: '225px' }}
              height={{ md: '64px', base: '32px' }}
              width={{ md: '64px', base: '32px' }}
              borderRadius="100%"
              bg="gray.200"
              animation={`${spinLeft} 25s linear infinite`}
            >
              <Image
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                alt="VTEX logo"
                src="/vtex-circle-logo.svg"
              />
            </Box>

            <Box
              id="outer-orbit-cirlces"
              position="absolute"
              top={{ md: '500px', base: '250px' }}
              left={{ md: '130px', base: '65px' }}
              height={{ md: '64px', base: '32px' }}
              width={{ md: '64px', base: '32px' }}
              borderRadius="100%"
              bg="gray.200"
              animation={`${spinLeft} 25s linear infinite`}
            >
              <Image
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                alt="TRAY logo"
                src="/tray-circle-logo.svg"
              />
            </Box>

            <Box
              id="outer-orbit-cirlces"
              position="absolute"
              top={{ md: '300px', base: '150px' }}
              left={{ md: '-26px', base: '-13px' }}
              height={{ md: '64px', base: '32px' }}
              width={{ md: '64px', base: '32px' }}
              borderRadius="100%"
              bg="gray.200"
              animation={`${spinLeft} 25s linear infinite`}
            >
              <Image
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                alt="Shopify logo"
                src="/shopify-circle-logo.svg"
              />
            </Box>

            <Box
              id="outer-orbit-cirlces"
              position="absolute"
              top={{ md: '50px', base: '25px' }}
              left={{ md: '30px', base: '15px' }}
              height={{ md: '64px', base: '32px' }}
              width={{ md: '64px', base: '32px' }}
              borderRadius="100%"
              bg="gray.200"
              animation={`${spinLeft} 25s linear infinite`}
            >
              <Image
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                alt="vnda logo"
                src="/vnda-circle-logo.svg"
              />
            </Box>
          </Box>
        </Box>
      </Flex>
    </Stack>
  );
}
