import { createTranslations } from '../../../utils/i18n';

interface Fields {
  title: string;
  ecoms: string;
  revenue: string;
  orders: string;
  investment: string;
}

export const translations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        title: 'We are growing, grow with us :)',
        ecoms: 'E-commmerces',
        revenue: 'Revenue',
        orders: 'Orders',
        investment: 'Ad spend tracked',
      },
      pt: {
        title: 'Estamos crescendo, vem crescer com a gente :)',
        ecoms: 'E-commmerces',
        revenue: 'Faturamento',
        orders: 'Pedidos',
        investment: 'Investimento em anúncios',
      },
    },
  });
