import { createTranslations } from '../../utils/i18n';

interface Fields {
  firstSentence: string;
  secondSentence: string;
  subheading: string;
  primaryButtonText: string;
  secondaryButtonText: string;
}

export const agenciesSectionTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        firstSentence: 'Have a 360º view of your store',
        secondSentence: 'And make decisions in seconds',
        subheading: "All of your clients KPI's in one page",
        primaryButtonText: 'Learn more',
        secondaryButtonText: "I'm an agency",
      },
      pt: {
        firstSentence: 'Tenha uma visão 360º do e-commerce',
        secondSentence: 'E tome decisões em segundos',
        subheading:
          'Tenha todos os principais indicadores dos seus clientes em uma única tela',
        primaryButtonText: 'Saiba mais',
        secondaryButtonText: 'Sou uma agência',
      },
    },
  });
