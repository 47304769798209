import { createTranslations } from '../../utils/i18n';

interface Fields {
  firstSentence: string;
  secondSentence: string;
  subheading: string;
  primaryButtonText: string;
}

export const goalsSectionTranslations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        firstSentence: 'Goals history',
        secondSentence: 'Scale with confidence',
        subheading:
          'Track your clients growth with ease through custom goals for each metric',
        primaryButtonText: 'Learn more',
      },
      pt: {
        firstSentence: 'Histórico de metas,',
        secondSentence: 'escale com confiança',
        subheading:
          'Acompanhe o crescimentos dos seus clientes de forma simples com metas personalizdas para cada métrica',
        primaryButtonText: 'Saiba mais',
      },
    },
  });
